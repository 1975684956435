import React, {useState, useContext, useEffect} from 'react';
import {Store} from '../../store';
import './terminalNameScreen.scss';
import {Icon} from 'semantic-ui-react';
import { translate } from "react-i18next";
import Swal from "sweetalert2";
import facebook from  './../../assets/images/facebook.svg'
import google from  './../../assets/images/google.png'
import youtube from  './../../assets/images/youtube.png'
import newspaper from  './../../assets/images/newspaper.png'
import twitter from  './../../assets/images/twitter.png'
import booking from  './../../assets/images/booking.png'
import rtl from  './../../assets/images/rtl.png'
import twitch from  './../../assets/images/twitch.png'



const electron = window.require('electron');
const {ipcRenderer} = electron;


const TerminalNameScreen = (props) => {
    const {state, dispatch} = useContext(Store);
    const [version, setVersion] = useState(0);
    const year = new Date().getFullYear();


    useEffect(() => {
        if (ipcRenderer !== false) {
            ipcRenderer.send('version-request', "version");
            ipcRenderer.on('version-request-reply', function (event, args){
                setVersion(args);
            });
        }
    }, [version])


    const	handleToUpdate	=	props.handleToUpdate;

    const openBrowser = (url) => {
        ipcRenderer.send('open-url',url)
    }
    const clickLegale = () => {
        Swal.fire({
            background :'linear-gradient(0deg, rgba(9, 24, 43, 0.922006) 25%, rgba(11, 76, 94, 0.83237) 100%)',
            width:'1000px',
            html : `<iframe src = "http://cdn.xpressgaming.net/terminal-files/luxembourg/legal-html/index.html" style = "width:100%; height:80vh;" frameBorder = "0" ></iframe>`
        })
    }

    const clikcQR= () => {
        Swal.fire({
            background :'linear-gradient(0deg, rgba(9, 24, 43, 0.922006) 25%, rgba(11, 76, 94, 0.83237) 100%)',
            imageUrl: 'http://cdn.xpressgaming.net/terminal-files/luxembourg/qr.jpg',
            imageWidth: 800,
            imageHeight: 800,
            width:'1000px'
        })
    }

    const getUserTime = () => {
        let userBalance = state?.init?.user?.balance;
        if(userBalance < 1 || typeof userBalance === 'undefined') {
            return '00:00';
        }
        let time = parseInt(userBalance) / 100;
        return time.toString().replace(/\./gi, ":")
    };

    const getCredits = () => {
        let balance = state?.init?.user?.balance;
        if(typeof balance === 'undefined') {
            return '0';
        }
        return balance;
    };



    return (
       <div className='terminal-name-screen'>
           <div className="terminal-name">
               <Icon name='computer' />
               <h2>{state.init.terminal.name} </h2>
           </div>
           <div className="version">
               <h4>Spinmatic Entertainment © {year} | v{version} {props.t('all_rights_reserved')}</h4>
           </div>
           <div className='internetInfo'>
               <div className='credit'>
                {getCredits()}
                <span>Credits</span>
               </div>
               <div className='time'>
                {getUserTime()}
                <span>Internet Time (min)</span>
               </div>
               <div className='hourInfo'>
                12EUR/Hour
                <span>Time Price</span>
               </div>
           </div>
            <div className='home'>
                <a  onClick={()=> openBrowser('www.facebook.com')}><img src={facebook}  alt="facebook" height="150" width="150"/></a>
                <a onClick={ ()=>openBrowser('www.google.com')}><img src={google} alt="google" height="75px" width="135px"/></a>
                <a onClick={()=> openBrowser('www.youtube.com')}><img src={youtube} height="150" width="150"/></a>
                <a onClick={()=> openBrowser('www.twitter.com')}><img src={twitter} height="75" width="150"/></a>
                <a onClick={()=> openBrowser('www.newspapers.com')}><img src={newspaper} height="55" width="140"/></a>
                <a onClick={()=> openBrowser('www.booking.com')}><img src={booking} height="45" width="130"/></a>
                <a onClick={()=> openBrowser('www.rtl.lu')}><img src={rtl} height="45" width="140"/></a>
                <a onClick={()=> openBrowser('www.twitch.tv/')}><img src={twitch} height="75" width="140"/></a>
                <a onClick={clikcQR}> <img src="http://cdn.xpressgaming.net/terminal-files/luxembourg/qr-thumbnail.jpg" height="140" width="140"/></a>
                <a></a>

            </div>
           <div className='actionButtons'>
               <a onClick={clickLegale}>{props.t("legale")}</a>
               <a onClick={() => openBrowser('www.google.com')}>{props.t("internet")}</a>
               {state.init.user !== null && state.init.user !== undefined && <a onClick={() => handleToUpdate(false)}>{props.t("promotional_games")}</a>}
           </div>

        </div>
    )
}

export default translate("translations")(TerminalNameScreen);
