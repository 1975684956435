import React from "react";
import ReactDOM from 'react-dom';
import App from './components/App';
import Login from './components/modules/login/Login'
import NotFound from './components/notFound/NotFound'
import Game from './components/game/Game';
import {HashRouter as Router, Switch, Route} from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css';
import {StoreProvider} from './store';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import './assets/css/odometer-theme-slot-machine.css'

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <StoreProvider>
            <Router>
                <Switch>
                    <Route exact path="/" component={NotFound}/>
                    <Route exact path="/login/:ticket_id" component={Login}/>
                    <Route exact path="/casino" component={App}/>
                    <Route exact path="/play" component={Game}/>
                </Switch>
            </Router>
        </StoreProvider>
    </I18nextProvider>
    , document.getElementById('app'));
