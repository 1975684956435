import React, { useContext, useEffect } from 'react'
import { Store } from '../../store'
import { Modal, Button, Icon, Input, ModalActions } from 'semantic-ui-react'
import { translate } from "react-i18next";
import Swal from 'sweetalert2'
import ApiService from '../../services/ApiService'
import './ModalComponent.scss'
import IPCPrint from "../../services/Print";
const JsBarcode = require('jsbarcode');

const ModalComponent = (props) => {    
    const { state } = useContext(Store);
    const [openModal, setOpenModal] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [checkButtons, setcheckButtons] = React.useState(true);
    const [hideRequestButtons, setHideRequestButtons] = React.useState(false);


    useEffect(() => {
        setOpenModal(props.openModal);
    })

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    function bindNumberToInput(event) {
        let number = event;
        let inputView = inputValue.trim() + number;
        setInputValue(inputView);
        inputView.length < 0 ? setcheckButtons(true) : setcheckButtons(false);
    }

    function text2Base64Barcode (text) {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, text);
        return canvas.toDataURL("image/jpg");
    };

    function payOutTicket() {
        let terminal_id = localStorage.getItem('terminal_id');
        let result = ApiService('/payment/ticket', { amount: inputValue, terminal_id: terminal_id });
        result.then(resp => {

            const {currency, username} = state.init.user;

            let {code} = resp.data.data;
            if (code !== undefined) {
                let exchangeEUR = inputValue / 100;
                let printAmount = `<b style="font-size: 20px;">${parseFloat(exchangeEUR)}</b>`;
                IPCPrint(name, text2Base64Barcode(code.toString()), printAmount, username);
            }


            if (resp.data.status) {
                closeModal();
                setInputValue('');
                lockScreen();
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: resp.data.messages[0],
                    background: 'red',
                    color: 'white'                
                }).then(r => { })
            }
        })
    }

    function lockScreen() {
        let terminal_id = localStorage.getItem('terminal_id');
        let result = ApiService('/terminal/reader/unlock', { tid: terminal_id });
        result.then(resp => {
            if (resp.status) {
                console.log('unlock success');
            }
        })
    }

    const closeModal = () => {
        props.closeModal(false);
        setInputValue('');
    }

    return (
        <Modal centered={false} open={openModal} >
            <Modal.Header>
                <div className='modal-header'>
                    <header>{props.t('payout')} ({state.init.user.balance})</header>
                    <Button className='actionButtons' onClick={closeModal} >{props.t("close")} <Icon name='close' /></Button>

                </div>
                <Input className='input-transform' value={inputValue} size='huge' />
            </Modal.Header>
            <Modal.Content className='modalContentClass'>
                <div className='modalButtons'>
                    {digits.map((item, index) => {
                        return (<Button key={index} className='number-button' id='number' value={item} onClick={() => bindNumberToInput(item.toString())}>{item}</Button>)
                    })}
                    <Button className='number-button' onClick={() => { setInputValue(''); setcheckButtons(true) }}>{props.t("reset")}</Button>
                    <Button className='number-button' value='0' onClick={() => bindNumberToInput('0')}>0</Button>
                    <Button className='number-button' onClick={
                        () => { setInputValue(state.init.user.balance); setcheckButtons(false) }}>{props.t("all")}</Button>
                </div>
            </Modal.Content>
            <ModalActions className='modalActionClass'>
                <Button disabled={checkButtons} className='actionButtons' onClick={() => payOutTicket()}>
                    <Icon name='send' />{props.t("payout_in_ticket")}
                </Button>
                {
                    hideRequestButtons ?
                        <Button className='actionButtons'><Icon name='send' />{props.t("request")}</Button> :
                        null
                }
            </ModalActions>
        </Modal>
    )
}

export default translate("translations")(ModalComponent);
