import React, {useContext, useEffect} from 'react';
import {Button, Icon} from 'semantic-ui-react'
import {Store} from "../../../store";
//import './DirectionButton.scss'
const terminalData = require('./../../../assets/external/terminal_ui');

const DirectionButton = (props) => {
    console.log(props);
    const {state, dispatch} = useContext(Store);


    const ScrollDown = () => {
        let header = document.getElementsByClassName('header');
        window.scrollBy({top :window.innerHeight - header[0].offsetHeight - 80, left : 0 , behavior: 'smooth'});
    }

    const ScrollUp = () => {
        let header = document.getElementsByClassName('header');
        window.scrollBy({top : -(window.innerHeight - header[0].offsetHeight - 80 ), left : 0 , behavior: 'smooth'});
    }

    const	handleToUpdate	=	props.handleToUpdate;

    return (
        <div className="direction-button-component">
            {
                props.direction === 'back' &&
                <Button>
                    <Icon name='arrow left' />
                </Button>
            }
            {
                props.direction === 'forward' &&
                <Button>
                    <Icon name='arrow right' />
                </Button>
            }
            {
                props.direction === 'up' &&
                <Button onClick={ScrollUp}>
                    <Icon name='arrow up' />
                </Button>
            }
            {
                props.direction === 'down' &&
                <Button onClick={ScrollDown}>
                    <Icon name='arrow down' />
                </Button>
            }
            {
                props.direction === 'home' &&
                <Button onClick={() =>handleToUpdate(true)}>
                    <Icon name='home' />
                </Button>
            }
        </div>
    )
}

export default DirectionButton;
