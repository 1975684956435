const axios = require('axios'); 
const qs = require('querystring');
import Cookies from 'universal-cookie';


const ApiService = async (path, params = {}) => {
    const cookies = new Cookies();

    const instance = axios.create({
        baseURL: `http://${process.env.API_PREFIX}.xpressgaming.net/api/v1`,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
    params['boToken'] = cookies.get('BO_TOKEN');
    params['domain'] = '	luxembourg.terminal';

    const postReq = function() {
      return instance.post(path, qs.stringify(params))        
    }

    return await postReq();
}


export default ApiService;
