import i18n from "i18next";

i18n.init({
    // we init with resources
    resources: {
        langs :[
            {slug :"en" ,text :'English'},
            {slug:'de' , text: 'Deutsch'},
            {slug :'tr' ,text : 'Türkçe'},
            {slug :'it' ,text : 'Italiano'},
            {slug :'es' ,text: 'Español'},
            {slug :'ru' ,text: 'русский'},
            {slug :'zh' ,text: '中文'}],

        en: {
            translations: {
                "test" : "translationTestEN",
                "playername":'Player Name',
                "balance":"Balance",
                "home" : "Home",
                "games" : "Games",
                "payout" : "Payout",
                "payin"  : "Pay-in",
                "language" : "Language",
                "machine_name" : "Machine Name",
                "dashboard" : "Dashboard",
                "transaction_history" : "Transaction History",
                "money" : "Money",
                "preparing" : "Preparing...",
                "close" : "Close",
                "reset" : "Reset",
                "payout_in_ticket" : "Payout in  Ticket",
                "request" : "Request",
                "all" : "All",
                "please_wait" : "Please wait...",
                "will_be_withdrawed": "will be withdrawed!",
                "are_you_sure": "Are you sure ?",
                "working": "working",
                "ok_btn": "OK",
                "cancel_btn": "Cancel",
                "unauthorized":"404 Unauthorized",
                "all_rights_reserved": "All rights reserved",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games",
            }
        },
        de: {
            translations: {
                "playername":'Spielername',
                "balance":"Balance",
                "test" : "testen",
                "home" : "Startseite",
                "games" : "Spiele",
                "payout" : "Auszahlen",
                "payin"  : "Einzahlen",
                "language" : "Sprache",
                "machine_name" : "Gerätename",
                "dashboard" : "Instrumententafel",
                "transaction_history" : "Transaktionsverlauf",
                "money" : "Geld",
                "preparing" : "Wird vorbereitet...",
                "close" : "Schließen",
                "reset" : "Zurücksetzen",
                "payout_in_ticket" : "Auszahlen als Ticket",
                "request" : "Anfordern",
                "all" : "Alle",
                "please_wait" : "Bitte warten",
                "will_be_withdrawed": "werden ausgezahlt!",
                "are_you_sure": "Sind Sie sicher?",
                "working": "in Arbeit",
                "ok_btn": "OK",
                "cancel_btn": "Abbrechen",
                "unauthorized":"404 Keine Berechtigung",
                "all_rights_reserved": "Alle Rechte vorbehalten",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games",
            }
        },
        es: {
            translations: {
                "playername":'Nombre del jugador',
                "balance":"Balance",
                "test" : "translationTestEN",
                "home" : "Inicio",
                "games" : "Juegos",
                "payout" : "Pago",
                "payin"  : "Depositar",
                "language" : "Idioma",
                "machine_name" : "Nombre de la máquina",
                "dashboard" : "Panel de control",
                "transaction_history" : "Historial de transacciones",
                "money" : "Dinero",
                "preparing" : "Preparando...",
                "close" : "Cerrar",
                "reset" : "Restablecer",
                "payout_in_ticket" : "Premio del cupón",
                "request" : "Solicitar",
                "all_rights_reserved" : "Todos los derechos reservados",
                "all" : "Todo(s)",
                "please_wait" : "Por favor aguarde...",
                "will_be_withdrawed": "se retirará(n)!",
                "are_you_sure": "Estas seguro?",
                "working": "procesando",
                "ok_btn": "OK",
                "cancel_btn": "Cancelar",
                "unauthorized":"404 No Autorizado",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games"
            }
        },
        it: {
            translations: {
                "playername":'Nome del giocatore',
                "balance":"Saldo",
                "test" : "translationTestEN",
                "home" : "Home",
                "games" : "Giochi",
                "payout" : "Prelievo",
                "payin"  : "Deposito",
                "language" : "Lingua",
                "machine_name" : "Nome del dispositivo",
                "dashboard" : "Dashboard",
                "transaction_history" : "Cronologia delle transazioni",
                "money" : "Denaro",
                "preparing" : "Preparazione...",
                "close" : "Chiudi",
                "reset" : "Ripristina",
                "payout_in_ticket" : "Pagamento in Ticket",
                "request" : "Richiesta",
                "all_rights_reserved" : "Tutti i diritti riservati",
                "all" : "Tutto",
                "please_wait" : "Attendere...",
                "will_be_withdrawed": "saranno prelevati!",
                "are_you_sure": "Sei sicuro?",
                "working": "in funzione",
                "ok_btn": "OK",
                "cancel_btn": "Annulla",
                "unauthorized":"404 - Non autorizzato",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games"
            }
        },
        ru: {
            translations: {
                "playername":'Имя игрока',
                "balance":"Баланс",
                "test" : "translationTestEN",
                "home" : "Главная",
                "games" : "Игры",
                "payout" : "Выплата",
                "payin"  : "Внести средства",
                "language" : "Язык",
                "machine_name" : "Название машины",
                "dashboard" : "Панель",
                "transaction_history" : "История транзакций",
                "money" : "Деньги",
                "preparing" : "Подготовка...",
                "close" : "Закрыть",
                "reset" : "Сбросить",
                "payout_in_ticket" : "Выплата в Тикет",
                "request" : "Запросить",
                "all_rights_reserved" : "Все права защищены",
                "all" : "Все",
                "please_wait" : "Пожалуйста, подождите...",
                "will_be_withdrawed": "будет снято!",
                "are_you_sure": "Вы уверены?",
                "working": "в процессе",
                "ok_btn": "Окей",
                "cancel_btn": "Отмена",
                "unauthorized":"404 Не авторизован",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games"
            }
        },
        tr: {
            translations: {
                "playername":'Oyuncu Adı',
                "balance":"Bakiye",
                "test" : "translationTestEN",
                "home" : "AnaSayfa",
                "games" : "Oyunlar",
                "payout" : "Payout",
                "payin"  : "Pay-in",
                "language" : "Dil",
                "machine_name" : "Makine Adı",
                "dashboard" : "Dashboard",
                "transaction_history" : "Transaction History",
                "money" : "Para",
                "preparing" : "Hazırlanıyor...",
                "close" : "Kapat",
                "reset" : "Reset",
                "payout_in_ticket" : "Payout in  Ticket",
                "request" : "Request",
                "all_rights_reserved" : "All rights reserved",
                "all" : "All",
                "please_wait" : "Please wait...",
                "will_be_withdrawed": "will be withdrawed!",
                "are_you_sure": "Are you sure ?",
                "working": "working",
                "ok_btn": "OK",
                "cancel_btn": "Cancel",
                "unauthorized":"404 Unauthorized",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games"
            }
        },
        zh: {
            translations: {
                "test" : "translationTestZH",
                "playername":'玩家名称',
                "balance":"硬币",
                "home" : "主页",
                "games" : "游戏类",
                "payout" : "派彩",
                "payin"  : "支付",
                "language" : "语言",
                "machine_name" : "机器名称",
                "dashboard" : "仪表盘",
                "transaction_history" : "交易记录",
                "money" : "钱",
                "preparing" : "正在准备……",
                "close" : "关闭",
                "reset" : "重置",
                "payout_in_ticket" : "门票支付",
                "request" : "请求",
                "all_rights_reserved" : "保留所有权利",
                "all" : "全部",
                "please_wait" : "请稍候……",
                "will_be_withdrawed": "将被撤回！",
                "are_you_sure": "你确定吗？",
                "working": "正在工作",
                "ok_btn": "好",
                "cancel_btn": "取消",
                "unauthorized":"404未经授权",
                "legale": "Legale",
                "internet": "Internet",
                "promotional_games": "Promotional Games",
            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
