import React from 'react';
import './Header.scss'
import { Store } from "../../../store";
import { Icon, Dropdown } from 'semantic-ui-react'
import { translate } from "react-i18next";
import i18next from "i18next";
import ModalComponent from '../../modal/ModalComponent'
import UsernameComponent from '../username/Username'
import MachineNameComponent from "../machineName/MachineName";
import BalanceComponent from "../balance/Balance";
import TranslateComponent from "../translate/TranslateComponent"
import LogoComponent from "../logo/Logo";
import JackpotComponent from '../jackpot/Jackpot'

const HeaderComponent = (props) => {
    const { state } = React.useContext(Store);
    const [openModal, setOpenModal] = React.useState(false);

    function closeModal() {
        setOpenModal(false);
    }

    return (
        state.module.modules.header.show && <header className='header'>
            {state.module.modules.header.contents.payout && <ModalComponent openModal={openModal} closeModal={closeModal} />}
            <div>
                {state.module.modules.header.contents.logo && <LogoComponent />}
            </div>
            {
                state.module.modules.header.contents.jackpot && <JackpotComponent />
            }
            <div className="header-items">
                {state.module.modules.header.contents.language && <TranslateComponent />}
                {
                    state.module.modules.header.contents.payout &&
                    <a className='payout-item' onClick={() => { setOpenModal(true) }}>
                        <h4 ><Icon name='money bill alternate outline' />{props.t("payout")}</h4><p style={{ marginTop: "-15px" }}>Money</p>
                    </a>
                }
                {
                    state.module.modules.header.contents.username &&
                    <UsernameComponent />
                }
                {
                    state.module.modules.header.contents.balance &&
                    <BalanceComponent />
                }
                {
                    state.module.modules.header.contents.terminal_name &&
                    <MachineNameComponent />
                }
            </div>
        </header>

    )
};
export default translate("translations")(HeaderComponent);
