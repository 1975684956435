const electron = window.require('electron');
const {ipcRenderer} = electron;

const IPCPrint = (terminal_name, barcode_image, amount, username) => {
    const d = new Date();
    const datestring = ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" +
    d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    const printData =
        '<table id="mainframe" style="text-align:center; margin:0 auto; padding: 5px">' +
        '<tbody>' +
        '<tr>' +
        '<td id="date">DATE ' + datestring + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td id="date"> ' + terminal_name + ' </td>' +
        '</tr>' +
        '<tr>' +
        '<td id="barcodetd">' +
        '<img src="' + barcode_image + '" />' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td id="date">' + amount + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td id="date"> <h2>' + username + '</h2></td>' +
        '</tr>' +
        '</tbody>' +
        '</table>' +
        '</body>';
    ipcRenderer.send('printDirect', printData);
}

export default IPCPrint;
